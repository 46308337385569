export const Bio = {
  name: "Gent Maloku",
  roles: [
    "Software Engineer",
    "UI/UX Designer",
  ],
  description:
    "Welcome to my portfolio page! I'm a 21-year-old enthusiastic full-stack developer and UI/UX designer from Prizren, Kosovo. Currently, I'm in the final year of my college studies, pursuing a Bachelor's degree in Computer Science with a specialization in Software Engineering",
  github: "https://github.com/genttmaloku",
  linkedin: "https://www.linkedin.com/in/gentmaloku/",
  twitter: "https://twitter.com/malokugentt",
  insta: "https://www.instagram.com/malokugentt/",
  facebook: "https://www.facebook.com/genttmaloku/",
};

export const skills = [
  {
    title: "",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "Git",
        image:
          "https://e7.pngegg.com/pngimages/713/558/png-clipart-computer-icons-pro-git-github-logo-text-logo-thumbnail.png",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
      },
      {
        name: "Figma",
        image:
          "Img/figma.png",
      },
      {
        name: "PHP",
        image:"https://www.php.net/images/logos/new-php-logo.svg",
      },
      {
        name:"Laravel",
        image:"https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg",
      },
      {
        name:"Agile",
        image:"Img/agile.png",
      },
      {
        name:"Tailwind",
        image:"Img/tailwind.jpg",
      },
      {
        name:"Unity",
        image:"https://imgs.search.brave.com/-Z3qyLsHKC-j0ZtTs5k9_qVdITedwW6PQpJzqR5mTdQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy82/MmUxMzFkZjdmZTM1/OTlmZGQ0NmVjYjMu/cG5n",
      },
      {
        name:"C#",
        image:"Img/csharp.jpg",
      }
      
    ],
  },
  
 
];

export const experiences = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/neurobit.jpeg?alt=media&token=1cacbb7b-e5ea-4efb-9b3d-f5a31b9564ad",
    role: "Frontend Engineer Intern",
    company: "Neurobit",
    date: "June 2023 - Nov 2023",
    desc: "Enhanced user experiences on Neurobit PSG & Hybrid, Portals by resolving bugs & reduced load time by 40%. Built Neurobit Analytics portal using React Js with seamless interaction of REST APIs using AXIOS optimized with React Query. Refactored previous code to TypeScript, updated with new dependency and integrated Vite with Jest for Unit Testing.",
    skills: [
      "ReactJS",
      "Redux",
      "NodeJs",
      "Material UI",
      "HTML",
      "CSS",
      "JavaScript",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Screenshot%20from%202024-01-25%2022-38-31.png?alt=media&token=2785903f-1a4e-41f5-afd2-6adcfe56d058",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
    role: "DevOps & Fullstack Engineering Intern",
    company: "Flipr Inovations Pvt. Ltd.",
    date: "Aug 2023 - Oct 2023",
    desc: "Built Flipr Connect Platforms using React Js integrated GraphQL with AXIOS, created High level Design and Figma design. Built Backend with GraphQL and Node JS and connected with MongoDb - Reducing API calls by 20%. Dockerized and automated with Kubernetes & Jenkins CI/CD deployed in AWS-EC2 added Prometheus & Grafana for monitoring.",
    skills: [
      "Docker",
      "Terraform",
      "AWS",
      "EC2",
      "Portainer",
      "Nginx",
      "JavaScript",
      "TypeScript",
      "Node Js",
      " Next Js",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1696514649120.jpeg?alt=media&token=e7f6757b-edfa-4138-a692-d6709eeef3e2",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Rudraksha.jpeg?alt=media&token=8f83f41e-d0a1-486f-9c7f-dd4cd1d09e8d",
    role: "Android Developer Intern",
    company: "Rudraksha Welffare Foundation",
    date: "June 2021 - Oct 2021",
    desc: "• Built RudraShakti - Astrologer App, with MVVM Architecture using Java and Android Studio integrating Firebase SDK. Created One to One video call integration with Socket.IO and Firebase cloud functions and integrated with Retrofit. Created Low Level Design and converted Figma design to XML code.",
    skills: [
      "Android",
      "Java",
      "Kotlin",
      "XML",
      "Node Js",
      "Cloud Firestore",
      "Firebase",
      "Figma",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/WhatsApp%20Image%202023-05-05%20at%2012.07.39%20AM.jpeg?alt=media&token=9f0e1648-568b-422d-bd0b-1f125f722245",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
    role: "Fullstack Externship",
    company: "Flipr",
    date: "June 2023 - July 2023",
    desc: "Built an employee management full stack web app used Docker and deployed on AWS ec2. I was the top performer in the program.",
    skills: [
      "ReactJS",
      "Redux",
      "NodeJs",
      "Material UI",
      "HTML",
      "CSS",
      "JavaScript",
      "Docker",
      "AWS",
      "MongoDB",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1691181448873.jpeg?alt=media&token=ee85eb8f-7247-43cd-9a1d-ce9f58ea62a6",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/gdsc.jpeg?alt=media&token=c162329c-efaa-4be8-a173-8d3f4c48ea70",
    role: "Android Developer",
    company: "DSC KIIT",
    date: "Nov2021 - Present",
    desc: "As an Android developer at the Google Developers Student Club (GDCS), I have had the opportunity to work on exciting projects and collaborate with talented developers who share my passion for technology. Through my involvement with GDCS, I have also had the opportunity to host and participate in numerous events, including hackathons, study jams, and workshops.",
    skills: [
      "Leadership",
      "Mobile Application Development",
      "Kotlin",
      "XML",
      "Figma",
    ],
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/girlScript.jpeg?alt=media&token=e656a621-cf3c-4230-bf0f-e74b4cec6035",
    role: "Open Source Contributor ",
    company: "GirlScript Summer of Code",
    date: "May 2023 - Present",
    desc: "Contributed to different open-source projects and learn from industry experts",
  },
];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Kiit.jpeg?alt=media&token=3839d520-c59d-4341-ad8f-0980c98de4dd",
    school: "Kalinga Institute of Industrial Technology, Bhubaneswar",
    date: "Oct 2021 - Sep 2025",
    grade: "8.41 CGPA",
    desc: "I am currently pursuing a Bachelor's degree in Computer Science and Engineering at Kalinga Institute of Industrial Technology, Bhubaneswar. I have completed 4 semesters and have a CGPA of 8.71. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I am also a member of the Google Developers Student Club (GDSC) at KIIT, where I am learning and working on exciting projects with a team of talented developers.",
    degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/methodist.png?alt=media&token=018a1b18-e2dd-4f34-8855-cff1b6b69fd3",
    school: "Methodist School, Dankuni",
    date: "Apr 2019 - Apr 2025",
    grade: "88.2%",
    desc: "I completed my class 12 high school education at Methodist School, Dankuni, where I studied Science with Computer Science.",
    degree: "ISC(XII), Science with Computer",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/methodist.png?alt=media&token=018a1b18-e2dd-4f34-8855-cff1b6b69fd3",
    school: "Methodist School, Dankuni",
    date: "Apr 2017 - Apr 2019",
    grade: "92.3%",
    desc: "I completed my class 10 education at Methodist School, Dankuni, where I studied Science with Computer Application.",
    degree: "ICSC(X), Science with Computer",
  },
];

export const projects = [
  {
    id: 11,
    title: "Grub2Go",
    description:
      "Grub2Go is a web application for restaurants that provides a virtual menu and online ordering services. Users can browse the menu and place orders easily. Administrators can manage the menu and view orders through their dashboard.",
    image:
      "Img/grub2go.jpg",
    tags: [
      "HTML",
      "css",
      "JavaScript",
      "PHP",
      "MySQL",
    ],
    category: "web app",
    github: "https://github.com/genttmaloku/Grub2Go",
  },
  {
    id: 9,
    title: "LegalBox v1",
    description:
      "A web application where lawyers can manage their legal work such as case management, client management, and legal consulting. On the other hand, there's also a client role where users can actively search for lawyers to work with, rate the lawyers, and share their legal issues. The web application comes with advanced search functionality so both lawyers and clients have an easier process of searching for desired content.",
    image:
      "Img/legalboxv1.jpg",
    tags: [
      "Laravel",
      "TailwindCss",
      "MySQL",

    ],
    category: "web app",
    github: "https://github.com/genttmaloku/LegalBox-v1",
  },
  {
    id: 0,
    title: "LegalBox v2",
    description:
      "A more advanced version of Legalbox, where lawyers have additional functionalities for scheduling meetings and filtering data. In this newer version of Legalbox, I have also implemented REST API and token authentication using Laravel Sanctum. The API was tested through Postman for backend functionality.",
    image:
      "Img/legalboxv2.jpg",
    tags: [ 
    "Laravel Sanctum",
    "TailwindCss",
    "MySQL",
    "Postman"],
    category: "web app",
    github: "https://github.com/genttmaloku/LegalBox-v2",
  },
  {
    id: 1,
    title: "Moti me Gentin (Weather App)",
    description:
      "Moti Me Gentin is a simple application that provides information about weather conditions in a specific location. Users can view the current temperature.",
    image:
      "Img/weather.jpg",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
    
    ],
    category: "web app",
    github: "https://github.com/genttmaloku/Weather-App",
    webapp: "https://motimegentin.netlify.app/",
  },
  {
    id: 2,
    title: "2K48",
    description:
      "2048 is a classic game where the player must move blocks within a grid to combine identical numbers and reach higher numbers such as 2048.",
    image:
      "Img/2k48.jpg",
    tags: [  
    "HTML",
    "CSS",
    "JavaScript",],
    category: "machine learning",
    github: "https://github.com/genttmaloku/Game-2k48",
    webapp: "https://2k48.netlify.app/",
  },
  {
    id: 3,
    title: "FlappyBird",
    description:
      "Flappy Bird is a simple arcade game where the player must control the movement of a bird to help it avoid pipes that appear in its path. This is a reproduction of a well-known game with the same name in the training provided by SHPIK.",
    image:
      "Img/flappy.jpg",
    tags: [
      "Unity",
      "C#"
    ],
    category: "android app",
    github: "https://github.com/genttmaloku/FlappyBird",
    webapp: "https://genttmaloku.itch.io/flappybird",
  },
  {
    id: 10,
    title: "Pong",
    description:
      "Pong is a classic arcade game where two players control paddles on opposite sides of the screen. The goal is to hit the ball back and forth, attempting to prevent it from passing their paddle while trying to score points by causing the ball to pass the opponent's paddle. This version is a reproduction of a well-known game with the same name from the training provided by SHPIK.",
    image:
      "Img/pong.jpg",
      tags: [
        "Unity",
        "C#"
      ],
    category: "android app",
    github: "https://github.com/genttmaloku/Pong",
    webapp: "https://genttmaloku.itch.io/pong",
  },
  {
    id: 4,
    title: "eKarriera",
    description:
      "eKarriera is a web application aimed at digitizing the process of hiring and employment in the Kosovo market. With the continuous development of technology in every possible industry in recent times, eKarriera is a contemporary and efficient solution for companies seeking employees and for individuals looking to find employment and advance their careers.",
    image:
      "Img/ekarriera.jpg",
    tags: [
      "Figma",
      "UI/UX",
    ],
    category: "web app",
    github: "https://www.figma.com/file/JteYii4WOilz6E3uEAEwFn/eKarriera?type=design&node-id=0%3A1&mode=design&t=lpfDkVTfSgSjE7Mo-1",
  },
  {
    id: 5,
    title: "UBT-Forums",
    date: "Jun 2024",
    description:
      " UBT Forums is an app developed by students, for students. Considering the lack of official platforms for communication and information sharing among UBT students, our team has decided to develop a dedicated university application to help students connect with each other using a more user-friendly application .",
    image:
      "Img/ubtforums.png",
    tags: ["React Js", "Inertia", "Laravel 11", "Tailwind CSS", "MySQL", "Redis"],
    category: "web app",
    github: "",
    webapp: "",
  },
  {
    id: 6,
    title: "Digital Menu - Yes Please",
    date: "Jul 2024",
    description:
      "The digital menu project for the fast food company Yes Please involves the creation of an application that digitizes the process of selecting and ordering food. This project provides a simple and clean user interface that allows customers to explore and filter foods by category and ingredients.",
    image:
      "Img/YP2.png",
    tags: ["HTML", "CSS", "JavaScript"],
    category: "website",
    github: "",
    webapp: "https://yesplease-pz.netlify.app/",
  },
  {
    id: 7,
    title: "Coffee Baber",
    date: "May 2024 - Aug 2024",
    description:
      "This app is designed to help Coffee Barber customers make appointments at a time and date that suits you best. Moreover, you can choose the desired service and ensure that everything is according to your preferences The application includes several functionalities such as: Appointment booking in real time, Choosing the date and time that suits you best, Selecting the services you want.",
    image:
      "Img/CB.png",
    tags: ["Laravel 11", "React", "Tailwind", "MySQL"],
    category: "android app",
    github: "",
    webapp: "https://frizeralpay.com/",
  },
  // {
  //   id: 8,
  //   title: "Face Recognition",
  //   date: "Jan 2021",
  //   description:
  //     "A Face recognition python app made with OpenCV. It uses face_recognition library to detect faces. It uses the webcam to detect faces. It also has a search bar to search for a particular face.",
  //   image:
  //     "https://dontrepeatyourself.org/media/face-recognition-with-python-dlib-and-deep-learning_cezKZBj.png",
  //   tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
  //   category: "machine learning",
  //   github: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  //   webapp: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  // },
];

